$text-color:       #ffffff;
$background-color: #4096cf;
$brand-color:      #0F4D92;
$grey-color:       #d0d0d0;
// inverted!
$grey-color-dark: #f0f0f0;
$grey-color-light: #c0c0c0;

@import "minima";

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

hr {
    border-style: none;
    border-top: 1px solid $grey-color;
    margin: 0.5em 0;
}

div.site-nav {
    clear: both;
}

.lang-selector {
    float: right;
}

.lang-selector li{
    display: inline flow-root;
}

.lang-selector img {
    border: 1px solid white;
}

.site-header {
  padding-top: 5px;
  border-top: 0;
}

$opificio-font-path: "/assets";

@font-face {
    src: url(#{$opificio-font-path}/Opificio.woff2) format("woff2"),
        url(#{$opificio-font-path}/Opificio.woff) format("woff");

    font-family: "Opificio";
    font-weight: 100;
}

h1 {
    font-family: "Opificio", $base-font-family;
}